<template>
	<span @click="copyToClipboard">
		<slot />
	</span>
</template>
<script>
	export default {
		name: 'clicktocopy',

		props: {
			text: {
				Type: String,
				Required: true
			}
		},

		methods: {
			copyToClipboard() {
				var copyText = this.text;
				var input_temp = document.createElement('textarea');
				input_temp.innerHTML = copyText;
				document.body.appendChild(input_temp);
				input_temp.select();
				input_temp.setSelectionRange(0, 99999); /*For mobile devices*/
				document.execCommand('copy');
				input_temp.remove();

				this.notify(`copied to clipboard - ${input_temp.value}`);
			}
		}
	}
</script>