<template>
	<b-container fluid class="pagecontainer">
		<b-row>
			<b-col>
				<b-alert variant="danger" v-if="error" show dismissible>
					ERROR: {{error}}
				</b-alert>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-card no-body>
					<b-card-text>
						<span class="pl-2 h3">Users</span>
						<div class="float-right">
							<b-btn variant="success" @click="doReset">
								<fa-icon icon="sync" />
							</b-btn>
							<b-btn variant="success" @click="doGetUsers" v-if="users && users.nextPageToken">
								<fa-icon icon="chevron-down" />
							</b-btn>
						</div>
						<br />
						<b-table striped hover responsive :fields="userfields" :items="users.users">
							<template #cell(#)="data">
								{{data.index+1}}
							</template>
							<template #cell(id)="data">
								<b-link :to="{name: 'manageuser', params: {id: data.value}}">
									<fa-icon icon="eye" /> View
								</b-link>
								<section class="pt-2">
									<div class="h5">Id</div>
									<section :title="data.value">
										<clicktocopy :text="data.value">
											{{data.value}}
										</clicktocopy>
									</section>
								</section>
								<section class="pt-2">
									<div class="h5">Email</div>
									<section :title="data.item.email">
										<clicktocopy :text="data.item.email">
											{{data.item.email}}
										</clicktocopy>
									</section>
								</section>
								<section class="pt-2">
									<div class="h5">Display Name</div>
									<section :title="data.item.userDisplayName">
										<b-form inline>
											<b-form-input v-model="data.item.userDisplayName" placeholder="display name" />
											<b-btn variant="success" @click="doUpdateUserInfo(data.item)" title="click to save user">
												<fa-icon icon="save" />
											</b-btn>
										</b-form>
									</section>
								</section>
							</template>
							<template #cell(isprocessing)="data">
								<b-spinner v-show="data.item.isprocessing" variant="success" label="loading..." />
							</template>
							<template #cell(lockoutEnabled)="data">
								<template v-if="!isAdminId(data.item.id)">
									<b-btn v-if="data.item.lockoutEnabled" variant="success" @click="doUnlockUser(data.item)" title="click to unlock user">
										<fa-icon icon="lock-open" /> Unlock
									</b-btn>
									<b-btn v-else variant="danger" @click="doLockUser(data.item)" title="click to lock user">
										<fa-icon icon="lock" /> Lock
									</b-btn>
								</template>
								<template v-if="data.item.lockoutEndAtUtc">
									<br />Locked out till: {{data.item.lockoutEndAtUtc | todatetime | localdatetime}}
								</template>
							</template>
							<template #cell(rolesList)="data">
								<template v-if="data.value && data.value.length">
									<div class="h4 px-2 py-1" v-for="r in data.value" :key="'role'+r.id">
										<b-badge v-if="r.id">
											{{r.name}}
											<fa-icon v-if="!isAdminId(data.item.id)" icon="times" @click="doRemoveRole(data, r.id)" />
										</b-badge>
									</div>
								</template>
								<div v-else>(no roles)</div>
								<b-form inline v-if="!isAdminId(data.item.id)">
									<b-form-group>
										<b-form-select v-model="data.item.addroleid" :options="ROLESLIST" value-field="id" text-field="role" />
										<b-btn variant="success" @click="doAddRole(data)" title="click to add role">
											<fa-icon v-if="!isAdminId(data.item.id)" icon="plus" />
										</b-btn>
									</b-form-group>
								</b-form>
							</template>
							<template #cell(claimsList)="data">
								<template v-if="data.value && data.value.length">
									<div class="h4 px-2 py-1" v-for="c in data.value" :key="'c'+c.name">
										<b-badge v-if="c.name">
											{{c.name}}&nbsp;
											<fa-icon v-if="!isAdminId(data.item.id)" icon="times" @click="doRemoveClaim(data, c.name)" />
										</b-badge>
									</div>
								</template>
								<div v-else>(no claims)</div>
								<b-form inline v-if="!isAdminId(data.item.id)">
									<b-form-group>
										<b-form-select v-model="data.item.addclaimid" :options="CLAIMSLIST" value-field="name" text-field="value" />
										<b-btn variant="success" @click="doAddClaim(data)" title="click to add claim">
											<fa-icon icon="plus" />
										</b-btn>
									</b-form-group>
								</b-form>
							</template>
						</b-table>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script lang="js">
	import { mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';
	import clicktocopy from '@/components/clicktocopy';
	import { ROLES, CLAIMS, ADMIN_USER_ID } from '@/common/constants';

	export default {
		name: 'manage_users',

		components: {
			clicktocopy,
		},

		data() {
			return {
				baseurl: '//' + window.location.hostname,

				error: null,
				isprocessing0: false,
				userfields: [
					{ key: 'id', label: 'Id', tdClass: 'column25' },
					{ key: 'lockoutEnabled', label: 'Lock Status', tdClass: 'column10' },
					{ key: 'rolesList', label: 'Roles', tdClass: 'column20' },
					{ key: 'claimsList', label: 'Claims', tdClass: 'column20' },
					{ key: 'isprocessing', label: 'Processing', tdClass: 'column1' },
				],
				users: {
					nextPageToken: null,
					users: []
				},

				CLAIMSLIST: CLAIMS,
				ROLESLIST: ROLES,
				ADMIN_USER_ID
			}
		},

		async mounted() {
			this.doGetUsers();
		},

		methods: {
			...mapActions([
				"getUsers",
				"lockUser",
				"unlockUser",
				"addUserRole",
				"removeUserRole",
				"addUserClaim",
				"removeUserClaim",
				"updateCurrentUserInfo",
			]),

			isAdminId(id) {
				return id == this.ADMIN_USER_ID
			},

			async doReset() {
				const that = this
				DEBUG.log('doReset...')

				that.error = null;
				try {
					const results = await that.getUsers({});
					DEBUG.log('doReset - RESULTS - ', results)
					that.users.nextPageToken = results.nextPageToken;
					that.users.users = results.users;
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${that.error}`)
				}
			},

			async doGetUsers() {
				const that = this
				DEBUG.log('doGetUsers...')

				that.error = null;
				try {
					const { nextPageToken } = that.users;
					const filter = { nextPageToken };
					const results = await that.getUsers(filter);
					DEBUG.log('doGetUsers - RESULTS - ', results)
					that.users.nextPageToken = results.nextPageToken;
					that.users.users.push(...results.users.map(u => {
						u.isprocessing = false;
						return u;
					}));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${that.error}`)
				}
			},

			async doUpdateUserInfo(user) {
				const that = this;
				try {
					user.isprocessing = true;
					const userId = user.id;
					const userDisplayName = user.userDisplayName;
					await that.updateCurrentUserInfo({ userId, userDisplayName });
					that.notifySuccess('user info updated');
				}
				catch (error) {
					that.notifyError(`an error occurred - ${error}`)
				} finally {
					user.isprocessing = false;
				}
			},

			async doLockUser(user) {
				const that = this;
				try {
					user.isprocessing = true;
					await that.lockUser(user.id);
					user.lockoutEnabled = true;
					that.notifySuccess('user locked');
				}
				catch (error) {
					that.notifyError(`an error occurred - ${error}`)
				} finally {
					user.isprocessing = false;
				}
			},

			async doUnlockUser(user) {
				const that = this;
				try {
					user.isprocessing = true;
					await that.unlockUser(user.id);
					user.lockoutEnabled = false;
					that.notifySuccess('user unlocked');
				}
				catch (error) {
					that.notifyError(`an error occurred - ${error}`)
				} finally {
					user.isprocessing = false;
				}
			},

			async doAddRole(data) {
				const that = this;
				const user = data.item;
				try {
					user.isprocessing = true;
					const roleid = user.addroleid;
					if (!roleid) {
						alert('pick a role to add');
						return;
					}

					const { rolesList } = await that.addUserRole({
						id: user.id,
						roleId: roleid
					});
					data.item.rolesList = rolesList;
					that.notifySuccess('added role');
				}
				catch (error) {
					that.notifyError(`an error occurred - ${error.message}`)
				} finally {
					user.isprocessing = false;
				}
			},

			async doRemoveRole(data, roleId) {
				const that = this;
				const user = data.item;
				try {
					user.isprocessing = true;
					const { rolesList } = await that.removeUserRole({
						id: user.id,
						roleId
					});
					data.item.rolesList = rolesList;
					that.notifySuccess('removed role');
				}
				catch (error) {
					that.notifyError(`an error occurred - ${error.message}`)
				} finally {
					user.isprocessing = false;
				}
			},

			async doAddClaim(data) {
				const that = this;
				const user = data.item;
				try {
					user.isprocessing = true;
					const claimType = user.addclaimid;
					if (!claimType) {
						alert('pick a claim to add');
						return;
					}

					const { claimsList } = await that.addUserClaim({
						id: user.id,
						claimType
					});
					data.item.claimsList = claimsList;
					that.notifySuccess('added claim');
				}
				catch (error) {
					that.notifyError(`an error occurred - ${error.message}`)
				} finally {
					user.isprocessing = false;
				}
			},

			async doRemoveClaim(data, claimType) {
				const that = this;
				const user = data.item;
				try {
					user.isprocessing = true;
					const { claimsList } = await that.removeUserClaim({
						id: user.id,
						claimType
					});

					data.item.claimsList = claimsList;
					that.notifySuccess('removed claim');
				}
				catch (error) {
					that.notifyError(`an error occurred - ${error.message}`)
				} finally {
					user.isprocessing = false;
				}
			},
		}
	}
</script>
<style>
	.pagecontainer {
		max-height: 85vh;
		overflow-y: auto;
	}

	.column1 {
		width: 1%;
	}

	.column10 {
		width: 10%;
	}

	.column20 {
		width: 20%;
	}

	.column25 {
		width: 25%;
	}
</style>